import { captureRemixErrorBoundaryError } from '@sentry/remix'
import {
    ClientLoaderFunctionArgs,
    Links,
    Meta,
    Outlet,
    Scripts,
    ScrollRestoration,
    isRouteErrorResponse,
    redirect,
    useLocation,
    useRouteError,
} from '@remix-run/react'
import './tailwind.css'
import Navbar from './components/Navbar'

import globalState from './lib/state'
import { QueryClientProvider } from '@tanstack/react-query'
import { api, queryClient } from './lib/client'
import i18next from 'i18next'
import { Capacitor } from '@capacitor/core'
import { jwtDecode } from 'jwt-decode'

export function Layout({ children }: { children: React.ReactNode }) {
    return (
        <html lang="en">
            <head>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <Meta />
                <Links />
            </head>
            <body className="flex h-full flex-col items-center font-Rubik">
                {children}
                <ScrollRestoration />
                <Scripts />
            </body>
        </html>
    )
}

let checkTokenInThisSession = false
export async function clientLoader(req: ClientLoaderFunctionArgs) {
    const token = globalState.getState().accessToken

    const onAuthPages = req.request.url.includes('/auth/')
    const isOperator = import.meta.env.VITE_MOCK_MOBILE === 'true'
    if (!token && !onAuthPages) {
        if (isOperator) {
            return redirect('/auth/login')
        } else {
            return redirect('/auth/register')
        }
    }
    if (token) {
        if (!checkTokenInThisSession) {
            const decodedToken = token ? jwtDecode(token) : null
            const tokenExpirationDate = new Date(decodedToken.exp * 1000)
            const weekFromExpirationDate = new Date(
                tokenExpirationDate
            ).setDate(tokenExpirationDate.getDate() - 7)

            if (weekFromExpirationDate < Number(new Date())) {
                const res = await api.apiTokenRefresh.apiTokenRefreshCreate({
                    refresh: globalState.getState().refreshToken,
                })
                globalState.getState().setAccessToken(res.access)
                api.http.instance.defaults.headers['Authorization'] =
                    'Bearer ' + token
            }
            checkTokenInThisSession = true
        } else {
            api.http.instance.defaults.headers['Authorization'] =
                'Bearer ' + token
        }
    }

    if (token && onAuthPages) {
        if (
            Capacitor.isNativePlatform() ||
            import.meta.env.VITE_MOCK_MOBILE === 'true'
        ) {
            return redirect('/operator/home')
        }

        return redirect('/')
    }

    return true
}

// export const ErrorBoundary = () => {
//     const error = useRouteError()
//     captureRemixErrorBoundaryError(error)
//     return <div>Something went wrong</div>
// }

export default function App() {
    const url = useLocation()
    return (
        <main
            className="flex min-h-screen w-full flex-col lg:flex-row"
            style={{ direction: i18next.language === 'he' ? 'rtl' : 'ltr' }}
        >
            {!url.pathname.includes('/operator') && <Navbar />}
            <div className="hidden w-20 lg:block"></div>
            <QueryClientProvider client={queryClient}>
                <Outlet />
            </QueryClientProvider>
        </main>
    )
}
